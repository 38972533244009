import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'antd'
import Product from '../shop/Product'

/**
 * Composant carousel srd de la home
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function HomeFavor(props) {
  const favorArray = []

  props.products.forEach((product) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    favorArray.push(<Product {...product} />)
  })

  return (
    <section>
      <div className="top-title">
        <h2>Vos bijoux préférés</h2>
        <div className="sub_title">Découvrez ici les coups de coeur de nos clientes !<br />
          Craquez à votre tour pour le cadeau idéal…<br />
          ou simplement pour vous faire plaisir à petit prix !<br />
        </div>
      </div>
      <Row>{favorArray}</Row>
    </section>
  )
}

HomeFavor.propTypes = {
  products: PropTypes.array.isRequired,
}

export default HomeFavor
