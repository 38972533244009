import React from 'react';
import { Card, Skeleton, Image } from 'antd';
import './ProductCard.scss';
import BootstrapIcon from '../core/icons/BootstrapIcon';
import { FALLBACK_IMAGE } from '../../constants/core/core_constants';

const { Meta } = Card;
const active = true;

const actions = [
  <BootstrapIcon name="search" />,
  <BootstrapIcon name="heart-fill" />,
  <BootstrapIcon name="basket3-fill" />,
];

function ProductCard({ product }) {
  const [firstImage, secondImage] = product.pictures;

  return (
    <Card
      hoverable
      style={{
        width: 240,
        margin: '10px',
      }}
      actions={actions}
      cover={product.pictures.length ? (
        <Image
          alt={product.nomArticle}
          src={product.pictures[0]}
          fallback={FALLBACK_IMAGE}
          onMouseOver={(e) => { if (product.pictures[1]) e.currentTarget.src = secondImage; }}
          onMouseOut={(e) => { e.currentTarget.src = firstImage; }}
          onFocus={(e) => { if (product.pictures[1]) e.currentTarget.src = secondImage; }}
          onBlur={(e) => { e.currentTarget.src = firstImage; }}
        />
      ) : (
        <Skeleton.Image active={active} style={{ width: '238px', height: '238px' }}/>
      )}
    >
      <Meta style={{ height: '100px' }} title={product.designation} description={product.descriptif} />
    </Card>
  );
}

export default ProductCard;
