import React from 'react'
import {
  Carousel,
  Image,
} from 'antd'
import PropTypes from 'prop-types'

/**
 * Composant carousel srd de la home
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function HomeCarousel(props) {
  const carouselArray = []

  props.images.forEach((image) => {
    carouselArray.push(<Image src={image.src} />)
  })

  return (
    <Carousel autoplay>
      {carouselArray}
    </Carousel>
  )
}

HomeCarousel.propTypes = {
  images: PropTypes.array.isRequired,
}

export default HomeCarousel
