import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import {
  Button, Image, Row, Col,
} from 'antd'
import './MegaMenu.scss'
import { DEFAULT_TOP_MENU, DEFAULT_FAMILIES } from '../../../constants/core/navigation_constants'

import Logo from './Logo'

/**
 * Menu principal
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function MegaMenu() {
  const [dropdownOpen, setDropdownOpen] = useState(Array(DEFAULT_TOP_MENU.length).fill(false))

  /**
   * Change le statut d'ouverture du menu
   * @param {number} index
   */
  const toggleDropdownOpen = (index) => {
    const newDropdown = [...dropdownOpen]
    newDropdown.forEach((e, i) => {
      // eslint-disable-next-line no-console
      newDropdown[i] = false
    })
    if (index !== undefined) {
      newDropdown[index] = true
    }
    setDropdownOpen(newDropdown)
  }

  /**
   * Contenu des dropdown
   */
  const buildDropDown = (index) => {
    if (DEFAULT_TOP_MENU[index].name) {
      const obj = DEFAULT_FAMILIES[DEFAULT_TOP_MENU[index].name]
      const content = []
      obj.children.forEach((child) => {
        const childContent = []
        child.children.forEach((c) => {
          childContent.push(<Row><a className="nav-link" href={c.link}>{c.label}</a></Row>)
        })
        childContent.push(<Row>&nbsp;</Row>)
        childContent.push(<Row gutter={[-30, -30]}><a className="nav-link bold" href={child.link}>{child.label}</a></Row>)
        content.push(<Col>{childContent}</Col>)
      })
      content.push(<Col><Image width={310} src={obj.image}/></Col>)
      return (
        <>
          <Row gutter={[16, 16]}>{content}</Row>
          <Row>&nbsp;</Row>
          <Row gutter={[16, 16]}><a className="nav-link bold" href={obj.link}>{obj.text}</a></Row>
        </>
      )
    }
    return <>.</>
  }

  /**
   * construction du menu
   * @return {Array}
   */
  const buildMenu = () => {
    const finalMenu = []
    DEFAULT_TOP_MENU.forEach((mainMenu, index) => {
      switch (mainMenu.type) {
        case 'logo':
          finalMenu.push(<Logo/>)
          break;
        case 'button':
          finalMenu.push(<Button type="primary" href={mainMenu.link}>{mainMenu.label}</Button>)
          break
        case 'link':
        case 'menu':
        default:
          finalMenu.push(
            // eslint-disable-next-line react/no-array-index-key
            <Dropdown isOpen={dropdownOpen[index]} toggle={() => { }} key={index}
                      onMouseLeave={() => toggleDropdownOpen()}
                      className="navbar-item navbar-user dropdown main-menu" tag="div"
            >
              <DropdownToggle tag="a" className="navbar-link dropdown-toggle d-flex align-items-center">
                <Button type="text" style={{ marginTop: '10px', padding: '0px' }}
                        onMouseOver={() => toggleDropdownOpen(index)}
                        onClick={(e) => e.preventDefault()}
                        className="big-menu-btn"
                >
                  {mainMenu.label}
                </Button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu" tag="div">
                {buildDropDown(index)}
              </DropdownMenu>
            </Dropdown>,
          )
      }
    })
    return finalMenu
  }

  return (
    <div className="navbar-nav">
      {buildMenu()}
    </div>
  )
}

export default MegaMenu
