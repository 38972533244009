export const HOME_STRUCTURE = [
  'carousel', 'favor_products', 'new_collection', 'discover', 'newsletter',
]

export const DEFAULT_CAROUSEL = [
  {
    src: 'https://www.srdbijoux.fr/modules/ps_imageslider/images/9dfff010ac349777f45abfa35d5ec52ef4684869_1800x850-AH-2023-2024-CREATIONS.jpg',
    href: 'https://www.srdbijoux.fr/',
  }, {
    src: 'https://www.srdbijoux.fr/modules/ps_imageslider/images/af254973f908da616716282d67c29d39e03ca5ac_1800x850-AH-2023-2024-NATURELLES.jpg',
    href: 'https://www.srdbijoux.fr/',
  },
]

export const DEFAULT_COLLECTION = {
  label: 'Nouvelle collection',
  tabs: [{
    label: 'Bagues',
    products: [
      {
        image: ['http', 'http'],
        link: 'http',
        name: '',
        price: 32.90,
        label: 'Elise',
      },
    ],
  },
  ],
}

export const DEFAULT_FAVOR = [{
  label: 'Danakil',
  price: 32.10,
  images: ['https://www.srdbijoux.fr/3146499-pdt_540/danakil.jpg', 'https://www.srdbijoux.fr/3146500-pdt_540/danakil.jpg'],
  link: 'http',
}, {
  label: 'Danakil',
  price: 32.10,
  images: ['https://www.srdbijoux.fr/3146499-pdt_540/danakil.jpg', 'https://www.srdbijoux.fr/3146500-pdt_540/danakil.jpg'],
  link: 'http',
}]

export const DEFAULT_DISCOVER = [{
  image: 'http',
  title: 'La vie',
  text: 'en rose',
  link: 'https:',
}]
