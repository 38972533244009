import React from 'react'
import './HomeView.scss'
import {
  Alert,
} from 'antd'
import { DEFAULT_CAROUSEL, DEFAULT_FAVOR, HOME_STRUCTURE } from '../../constants/home/home_constants'
import HomeCarousel from './HomeCarousel'
import HomeFavor from './HomeFavor'

/**
 * Page d'accueil
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function HomeView() {
  const elements = []
  HOME_STRUCTURE.forEach((e, i) => {
    switch (e) {
      case 'favor_products':
        elements.push(<HomeFavor products={DEFAULT_FAVOR}/>)
        break
      case 'carousel':
        elements.push(<HomeCarousel images={DEFAULT_CAROUSEL}/>)
        break
      default:
        // eslint-disable-next-line react/no-array-index-key
        elements.push(<Alert key={i} type="error" message={`${e} is not implemented`} />)
    }
  })
  return (
    <p className="margin-bottom">
      {elements}
    </p>
  )
}

export default HomeView
