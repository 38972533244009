import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import ServicesUnavailable from '../error/ServicesUnavailable'
import NotFound from '../error/NotFound'
import HomeView from '../../home/HomeView'
import SearchView from '../../search/SearchView'

function MainRoot() {
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/home"/>}/>
      <Route exact path="/home" element={<HomeView/>}/>
      <Route exact path="/search" element={<SearchView/>}/>
      <Route path="error_503" element={<ServicesUnavailable/>}/>
      <Route path="*" element={<NotFound/>}/>
    </Routes>
  )
}

export default MainRoot
