/* eslint-disable no-console */
export const initialState = {
  filters: [],
  products: [],
  token: '',
  selectedFilters: [],
};

/**
 * Gestion des états pour la navigation
 * @param {Object} state
 * @param {Object|null} action
 * @return {Object}
 */
export default function navigationReducer(state = initialState, action = null) {
  const { type, payload } = action;
  switch (type) {
    case 'LOAD_PRODUCTS':
      return { ...state, products: payload };
    case 'LOAD_FILTERS':
      return { ...state, filters: payload };
    case 'SET_TOKEN':
      return { ...state, token: payload };
    case 'TOGGLE_FILTER': {
      const isMinMaxFilter = action.payload.name.startsWith('min') || action.payload.name.startsWith('max');
      let updatedFilters;

      if (isMinMaxFilter) {
        // Remplace tout filtre existant commençant par "min" ou "max" du même type
        updatedFilters = state.selectedFilters.filter(
          (filter) => (
            (!filter.name.startsWith('min') && !filter.name.startsWith('max'))
            || filter.name !== action.payload.name
          ),
        );
        updatedFilters.push(action.payload);
      } else {
        // Pour les filtres normaux, garder l'ancien comportement
        const filterExists = state.selectedFilters.some(
          (filter) => filter.name === action.payload.name
            && filter.label === action.payload.label,
        );
        updatedFilters = filterExists
          ? state.selectedFilters.filter(
            (filter) => (
              filter.name !== action.payload.name
              || filter.label !== action.payload.label
            ),
          )
          : [...state.selectedFilters, action.payload];
      }

      return {
        ...state,
        selectedFilters: updatedFilters,
      };
    }
    default:
      return state;
  }
}
