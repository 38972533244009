import React, { useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import './App.scss'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import MainLayout from './components/core/layout/MainLayout'
import './translations/i18n'
import store from './store/store'

/**
 * Gestion de l'application globale
 * @return {JSX.Element}
 * @constructor
 */
function App() {
  const [, setCookies] = useState('')
  const cookieInstance = instanceOf(Cookies).isRequired

  useEffect(() => {
    // Mise à jour des états locaux
    setCookies(cookieInstance.props)
  }, [])

  return (
    <Provider store={store}>
      <MainLayout/>
    </Provider>
  )
}

export default withCookies(App)
