/* eslint-disable no-console */
import Axios from 'axios'
import store from '../../store/store'
import { SEARCH_API_BASE_URL } from '../../constants/core/navigation_constants';

export const apiCall = (uri) => {
  const base64encodeurl = btoa(uri)
  const token = 'djhgJDg895(__88;jhtaA'
  const uriToCall = `https://commande.srdbijoux.fr/index/api?token=${token}&request=${base64encodeurl}!`
  return Axios.get(uriToCall)
}

export const filterFacette = () => {
  const state = store.getState(); // Accéder à l'état actuel du store Redux
  const { selectedFilters } = state.navigation;

  // Construire dynamiquement les filtres dans l'URI
  const filters = selectedFilters.map((filter) => ({
    field: filter.name,
    operator: 'contains', // Vous pouvez ajuster l'opérateur selon les besoins
    value: filter.label,
  }));

  const uri = `NavigationFacette/?filter=${encodeURIComponent(JSON.stringify({ filters }))}`;

  return apiCall(uri);
};

export const getUrlArguments = () => {
  const filterArray = {};
  let filtersUrl = '';

  // Remplir filterArray avec les filtres
  store.getState().navigation.selectedFilters.forEach((filter) => {
    if (!filterArray[filter.name]) {
      filterArray[filter.name] = [filter.label];
    } else {
      filterArray[filter.name].push(filter.label);
    }
  });

  // Parcourir les clés de filterArray avec Object.keys()
  Object.keys(filterArray).forEach((key) => {
    if (filtersUrl !== '') {
      filtersUrl += '&';
    }
    filtersUrl += `${key}=${filterArray[key].join(',')}`;
  });

  return filtersUrl;
};

export const loadFilters = () => {
  console.log(store.getState().token)
  if (store.getState().navigation.token) {
    Axios.get(`https://webapi.srdbijoux.fr/Facets?${getUrlArguments()}`, { headers: { Authorization: `Bearer ${store.getState().navigation.token}` } }).then((response) => {
      if (response.data) {
        store.dispatch({
          type: 'LOAD_FILTERS',
          payload: response.data,
        })
      }
    })
  } else {
    console.log('en attente de token')
  }

  // apiCall('NavigationFacette/').then((response) => {
  //  if (response.data.data) {
  //    store.dispatch({
  //      type: 'LOAD_FILTERS',
  //      payload: response.data.data,
  //    })
  //  }
  // })
}

export const loadProductImage = (product, callback = undefined) => {
  apiCall(`ImagesB2B/?idBijoux=${product}`).then((response) => {
    console.log(response)
    if (callback) {
      if (response.data && response.data.data && response.data.data.string) {
        callback(response.data.data.string)
      }
    }
  })
}

export const loadProduct = (product, callback = undefined) => {
  apiCall(`article/${product}`).then((response) => {
    console.log(response)
    if (callback) {
      callback(response)
    }
  })
}

export const loadProducts = (limit = 28, page = 0, add = false, callback = () => {}) => {
  let getResponse
  if (store.getState().navigation.token) {
    let filtersUrl = ''
    store.getState().navigation.selectedFilters.forEach((filter) => {
      if (filtersUrl !== '') {
        filtersUrl += '&'
      }
      filtersUrl += `${filter.name}=${filter.label}`
    })
    getResponse = Axios.get(`${SEARCH_API_BASE_URL}/Products/${limit}/${page}?${getUrlArguments()}`, { headers: { Authorization: `Bearer ${store.getState().navigation.token}` } }).then((response) => {
      if (response.data) {
        const products = store.getState().navigation.products || [];
        store.dispatch({
          type: 'LOAD_PRODUCTS',
          payload: add ? [...products, ...response.data] : response.data,
        });

        callback(response.data)
      }
    })
  } else {
    console.log('en attente de token')
  }
  return getResponse
}

export const loadFiltersAndProducts = () => {
  filterFacette.then((response) => {
    if (response.data.data) {
      store.dispatch({
        type: 'LOAD_PRODUCTS',
        payload: {
          payload: response.data.data,
        },
      })
    }
    if (response.data.ProductB2C) {
      store.dispatch({
        type: 'LOAD_FILTERS',
        payload: response.data.ProductB2C,
      })
    }
  })
}

export const toggleFilter = (filter) => ({
  type: 'TOGGLE_FILTER',
  payload: filter,
});

export const shopAuth = (callback = () => { }) => {
  Axios.post(`${SEARCH_API_BASE_URL}/Token/Generate/ShopWebsite?entity=srdfront&password=srdfront`)
    .then((response) => {
      callback(response.data)
      store.dispatch({
        type: 'SET_TOKEN',
        payload: response.data,
      })
    })
};
