import React from 'react'
import PropTypes from 'prop-types'
import NavBar from '../navbar/NavBar'
import MainRoot from './MainRoot'

/**
 * Composant principal pour l'affichage des menus
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function MainLayout(props) {
  return (
    <main>
      <div
        className={`app app-header-fixed app-sidebar-fixed${
          props.sidebar ? '' : ' app-sidebar-minified'}`}
      >
        <NavBar/>
        <div id="layout-content" className="app-content">
          {/* Must put Routes here because we need to get a correct initialized user (after keycloak init) */}
          <MainRoot/>
        </div>
      </div>
    </main>
  )
}

MainLayout.propTypes = {
  sidebar: PropTypes.bool,
}

MainLayout.defaultProps = {
  sidebar: false,
}

export default MainLayout
