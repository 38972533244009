export const DEFAULT_TOP_MENU = [{
  label: 'Bagues',
  name: 'bagues',
  link: 'https://www.srdbijoux.fr/20-bagues',
  type: 'menu',
}, {
  label: 'Bracelets',
  name: 'bracelets',
  link: 'https://www.srdbijoux.fr/21-bracelets',
  type: 'menu',
}, {
  label: 'Boucles d\'oreilles',
  type: 'menu',
}, {
  label: 'Colliers',
  type: 'menu',
}, {
  label: 'Nouveautés',
  type: 'link',
  link: 'https://www.srdbijoux.fr/81-nouveautes',
}, {
  type: 'logo',
}, {
  label: 'Bijoux homme',
}, {
  label: 'Bijoux enfant',
}, {
  label: 'La marque',
}, {
  label: 'Accès CSE',
  type: 'button',
  link: 'http://commande.srdbijoux.fr/',
},
]

export const DEFAULT_FAMILIES = {
  bagues: {
    label: 'Bagues',
    text: 'Voir Toutes nos Bagues',
    link: 'https://',
    image: 'https://www.srdbijoux.fr/themes/srd/assets/img/miniature_menu_bagues.jpg',
    children: [
      {
        label: 'Toutes nos bagues de couleur or',
        link: 'https://',
        children: [
          {
            label: 'Bagues plaqué or',
            link: 'https://',
          }, {
            label: 'Bagues dorées',
            link: 'https://',
          },
        ],
      }, {
        label: 'Toutes nos bagues de couleur argent',
        link: 'https://',
        children: [
          {
            label: 'BAGUES ARGENT 925‰',
            link: 'https://',
          }, {
            label: '.',
            link: 'https://',
          },
        ],
      },
    ],
  },
  bracelets: {
    label: 'Bracelets',
    text: 'Voir Toutes nos bracelets',
    link: 'https://',
    image: 'https://',
    children: [
      {
        label: 'Toutes nos bagues de couleur or',
        link: 'https://',
        children: [
          {
            label: 'Bagues plaqué or',
            link: 'https://',
          },
        ],
      },
    ],
  },
}

export const SEARCH_API_BASE_URL = 'https://webapi.srdbijoux.fr';
