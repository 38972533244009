import { Image } from 'antd'
import React from 'react'

/**
 * Logo dans le menu principal
 * @return {JSX.Element}
 * @constructor
 */
function Logo() {
  return (
    <a href="/" className="navbar-brand">
      <Image width={92} src="https://www.srdbijoux.fr/themes/srd/assets/img/modules/leobootstrapmenu/img/icons/srd-bijoux-logo-1626074803.jpg" preview={false} />
    </a>
  )
}

export default Logo
