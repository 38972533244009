import React from 'react'
import PropTypes from 'prop-types'
import './Header.scss'

/**
 * Entête de la page contenant le menu principal
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function Header(props) {
  return (
    <div className="app app-header ">
      <div id="header" className="app app-header" style={{ zIndex: 999 }}>
        {props.children}
      </div>
    </div>
  )
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Header
