/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import FilterSection from './FilterSection';
import { loadFilters, loadProducts } from '../../actions/core/apis';

const mapStateToProps = (state) => ({
  filters: state.navigation.filters,
  selectedFilters: state.navigation.selectedFilters,
})

function FilterView(props) {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    setFilters(props.filters);
  }, [props.filters]);

  useEffect(() => {
    loadProducts()
    loadFilters()
  }, [props.selectedFilters]);

  return (
    <div id="_desktop_search_filters_wrapper" className="visible--desktop">
      <div id="search_filter_controls" className="visible--mobile">
        <span id="_mobile_search_filters_clear_all" />
      </div>
      <div id="search_filters" className="search_filters">
        <p className="text-uppercase h6 visible--desktop">Filtrer par</p>
        {filters.map((filter) => (
          <FilterSection key={filter.title} type={filter.type} title={filter.label} facets={filter.indexedValues ? filter.indexedValues : filter.values} name={filter.name} />
        ))}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(FilterView);
