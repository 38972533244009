import React from 'react'
import { useTranslation } from 'react-i18next'
import Error from './Error'

/**
 * Gestion des pages avec accès non autorisé
 * @return {JSX.Element}
 * @constructor
 */
function NotFound() {
  const { t } = useTranslation()

  return (
    <Error
      title={t('generic.errors.errMsgNotFound')}
      message={t('generic.errors.errMsgNotFoundIhm')}
      status="404"
    />
  )
}

export default NotFound
