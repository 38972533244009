import React from 'react'
import { useTranslation } from 'react-i18next'
import Error from './Error'

/**
 * Affichage d'une erreur lorsque qu'aucun service n'est disponible
 * @return {JSX.Element}
 * @constructor
 */
function ServicesUnavailable() {
  const { t } = useTranslation()

  return (
    <Error
      title={t('generic.errors.errMsgServicesUnavailable')}
      message={t('generic.errors.errMsgServicesUnavailableIhm')}
      status="500"
    />
  )
}

export default ServicesUnavailable
