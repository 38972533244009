import React from 'react'
import PropTypes from 'prop-types'
import './BootstrapIcon.scss'

function BootstrapIcon(props) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <i className={`bootstrap-icon bi-${props.name}${props.className ? ` ${props.className}` : ''}`}
       onClick={props.onClick} role="button" tabIndex={0}
       onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} onPointerEnter={props.onPointerEnter}
       onPointerLeave={props.onPointerLeave} onFocus={props.onFocus}
    />
  )
}

BootstrapIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onPointerEnter: PropTypes.func,
  onPointerLeave: PropTypes.func,
  onFocus: PropTypes.func,
}

BootstrapIcon.defaultProps = {
  className: null,
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
  onPointerEnter: null,
  onPointerLeave: null,
  onFocus: null,
}

export default BootstrapIcon
