import React from 'react'
import Header from './Header'
import MegaMenu from './MegaMenu'
import './NavBar.scss'

/**
 * Menu principal dans l'entête
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function NavBar() {
  return (
    <Header>
      <MegaMenu/>
    </Header>
  )
}

export default NavBar
