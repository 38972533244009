/* eslint-disable no-console */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Flex } from 'antd';
import ProductCard from './ProductCard';
import './ProductList.scss';
import { loadProducts } from '../../actions/core/apis';

const mapStateToProps = (state) => ({
  products: state.navigation.products,
});

const defaultProps = {
  pageSize: 30,
  productPerRow: 4,
};

function ProductList(pushedProps) {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const props = { ...pushedProps, ...defaultProps };
  const loaderRef = useRef(null);

  // Charger les produits
  const loadMoreProducts = () => {
    if (loading) return;
    setLoading(true);

    try {
      loadProducts(28, page, true, (newProducts) => {
        if (newProducts && newProducts.length > 0) {
          setLoading(false);
          setPage((prevPage) => prevPage + 1);
        }
      });
    } catch (error) {
      console.error('Erreur lors du chargement des produits', error);
    }
  };

  useEffect(() => {
    // Initialiser avec les produits du store Redux si disponibles
    if (props.products) {
      setProducts(props.products);
    }
  }, [props.products]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreProducts();
        }
      },
      { threshold: 1 },
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loaderRef.current, products, page]); // Ajoutez les dépendances

  if (!products || products.length === 0) {
    return <p>Aucun produit disponible</p>; // Affichage si la liste est vide
  }

  const rows = [];
  for (let i = 0; i < products.length; i += props.productPerRow) {
    rows.push(
      <Flex justify="flex-end">
        <Row gutter={[16, 16]} key={i} style={{ marginBottom: '20px' }}>
          {Array.from({ length: props.productPerRow }).map((_, j) => (
            products[i + j] && (

              <ProductCard product={products[i + j]} />

            )
          ))}
        </Row>
      </Flex>,
    );
  }

  return (
    <>
      {rows}
      <div ref={loaderRef}>
        ...
      </div>
    </>
  );
}

export default connect(mapStateToProps)(ProductList);
