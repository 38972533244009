import React, { useState } from 'react'
import './Product.scss'
import {
  Col, Image, Row,
} from 'antd'
import PropTypes from 'prop-types'

/**
 * Composant produit
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function Product(props) {
  const [currentImage, setCurrentImage] = useState(props.images[0])

  const next = () => {
    if (props.images.length > 1) {
      setCurrentImage(props.images[1])
    }
  }

  const previous = () => {
    setCurrentImage(props.images[0])
  }

  return (
    <Col span={6}>
      <Image src={currentImage} onMouseOver={() => next()} onMouseLeave={() => previous()} preview={false}/>
      <Row>
        <Col className="product-title" span={12}>
          <a href={props.href}>{props.label}</a>
        </Col>
        <Col className="price" span={props.span}>{props.price} €</Col>
      </Row>
    </Col>
  )
}

Product.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  href: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  span: PropTypes.number,
}

Product.defaultProps = {
  span: 12,
}

export default Product
