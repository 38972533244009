import React from 'react'
import { Result } from 'antd'
import './Error.scss'
import PropTypes from 'prop-types'
import BootstrapIcon from '../icons/BootstrapIcon'

/**
 * Gestion des pages d'erreur
 * @return {JSX.Element}
 * @constructor
 */
function Error(props) {
  return (
    <>
      <BootstrapIcon name="x-circle" className="icon-invalid error-title-icon" />
      {props.title}
      <Result status={props.status} title={props.message} />
    </>
  )
}

Error.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

export default Error
