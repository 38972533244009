import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import frFR from 'antd/locale/fr_FR'
import reportWebVitals from './reportWebVitals'
import App from './App'
// css
import 'react-perfect-scrollbar/dist/css/styles.css'
import './index.scss'
import './scss/react.scss'

require('dayjs/locale/fr')

dayjs.locale('fr')

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <CookiesProvider>
      <ConfigProvider locale={frFR}>
        <App/>
      </ConfigProvider>
    </CookiesProvider>
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
