/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Flex, Breadcrumb, Layout } from 'antd';
import FilterView from './FilterView';
import ProductList from './ProductList';
import './SearchView.scss'
import { loadFilters, loadProducts, shopAuth } from '../../actions/core/apis';

const { Header, Sider, Content } = Layout;
const mapStateToProps = (state) => ({
  token: state.navigation.token,
})

const headerStyle = {
  borderRadius: 8,
  textAlign: 'center',
  color: '#000',
  height: 150,
  paddingInline: 48,
  lineHeight: '150px',
  backgroundColor: 'rgba(0, 0, 0, 0.03)',
};

const contentStyle = {
  textAlign: 'left',
  minHeight: 120,
  lineHeight: '120px',
  color: '#000',
  backgroundColor: '#fff',
};

const siderStyle = {
  textAlign: 'left',
  lineHeight: '10px',
  color: '#000',
  backgroundColor: '#fff',
};

const layoutStyle = {
  borderRadius: 8,
  overflow: 'hidden',
  padding: '0 7.43333%',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  backgroundColor: '#fff',
};

const titleStyle = {
  marginTop: '50px',
}

function SearchView(props) {
  const breadcrumbItems = [
    {
      title: <a href="https://www.srdbijoux.fr/">Accueil</a>,
    }, {
      title: 'BIJOUX FEMME',
    },
  ]

  useEffect(() => {
    shopAuth(() => { })
  }, []);
  useEffect(() => {
    if (props.token) {
      console.log(props.token)
      loadFilters()
      loadProducts()
    }
  }, [props.token]);

  return (
    <Flex gap="middle" wrap>
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <h1 style={titleStyle}>BIJOUX</h1>
        </Header>
        <Breadcrumb items={breadcrumbItems} />
        <Layout>
          <Sider width="10%" style={siderStyle}>
            <FilterView/>
          </Sider>
          <Content style={contentStyle}>
            <ProductList />
          </Content>
        </Layout>
      </Layout>
    </Flex>
  );
}

export default connect(mapStateToProps)(SearchView);
