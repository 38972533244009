/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, Divider, Slider } from 'antd';
import { toggleFilter as toggleFilterAction } from '../../actions/core/apis'; // Renommer l'importation

const mapStateToProps = (state) => ({
  selectedFilters: state.navigation.selectedFilters,
});

const mapDispatchToProps = {
  toggleFilter: toggleFilterAction, // Utiliser un alias pour éviter le conflit
};

function FilterSection({
  name,
  title,
  facets,
  selectedFilters,
  toggleFilter, // Récupération de l'action via les props
  type,
}) {
  const handleCheckboxChange = (facet) => {
    if (Array.isArray(facet)) {
      toggleFilter({ name: `min${name}`, label: facet[0] });
      toggleFilter({ name: `max${name}`, label: facet[1] });
    } else {
      toggleFilter({ name, label: facet });
    }
  };

  switch (type) {
    case 'decimal':
      return (
        <section className="facet__block">
          <h5>{title}</h5>
          <div className="custom-control custom-checkbox">
            <Slider range id={`facet_input_${title}`}
                    onChangeComplete={(value) => handleCheckboxChange(value)}
                    defaultValue={[parseFloat(facets[0]), (parseFloat(facets[facets.length - 1]) + 1)]} marks={{
                      0: '0',
                      [parseFloat(facets[facets.length - 1])]: parseFloat(facets[facets.length - 1]) + 1,
                    }} max={(parseFloat(facets[facets.length - 1]) + 1)}
            />
          </div>
        </section>
      )
    case 'string':
    default:
      return (
        <section className="facet__block">
          <h5>{title}</h5>
          <div id={`facet_${title}`} className="collpase--facet">
            {facets?.map((facet, index) => {
              const v = facet.value ? facet.value : facet
              const k = facet.key ? facet.key : facet
              const isChecked = selectedFilters.some(
                (filter) => filter.name === name && filter.label === k,
              );

              return (
                <div className="custom-control custom-checkbox" key={k}>
                  <Checkbox
                      id={`facet_input_${title}_${index}`}
                      checked={isChecked}
                      onChange={() => handleCheckboxChange(k)} // Gérer le changement ici
                  >
                    {v}
                  </Checkbox>
                </div>
              );
            })}
          </div>
          <Divider/>
        </section>
      );
  }
}

FilterSection.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  facets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  toggleFilter: PropTypes.func.isRequired, // Ajout de la validation pour toggleFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSection);
