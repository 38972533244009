import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import genericFr from './fr/generic.json'

const resources = {
  fr: {
    translation: {
      generic: genericFr,
    },
  },
}

i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
    debug: process.env.REACT_APP_ENV === 'DEV',
  })

export default i18next
